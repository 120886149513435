import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div className=' px-4 md:px-20 space-y-10  py-10 '>
            <h1 className='font-bold text-2xl pt-4 md:text-4xl'>About This Policy</h1>
            <p>
                This policy describes the personal information MedicoTech collects about you, why MedicoTech LLC. collects it and how MedicoTech LLC. uses it. MedicoTech LLC. policy also describes the choices you can make about how MedicoTech LLC. collects and uses your information.
            </p>
            <div className="space-y-5" >
                <h1 className="font-bold text-lg md:text-2xl">What Information Does MedicoTech Collect?</h1>
                <p className="text-sm md:text-lg">
                    We may collect the information you provide us while accessing, filling forms, submitting queries, requesting or signing up for services from us on our Website.
                    If you are interested in learning more about MedicoTech LLC.
                    and the services and products we offer, we may ask for personal information, such as your name, practice name and size, email address, telephone number, and city/state.
                    If you are an existing client and use our cloud services, we may also collect your username or other information you use to log into or access your account or patient data.
                    We do not collect or process any sensitive data such as: race or ethnic origin; political opinions; religious or philosophical beliefs; trade union memberships; genetic or biometric data; health or mortality; or sexual orientation in connection with this Website. When you visit our Website, we may gather certain information about your visit and your device automatically.
                    This information may, for example, reflect how you found, were directed to or used this Website. Similarly, we may collect your IP address, browser type and version, and other data about the equipment used to visit the Website, as well as the patterns of searching and browsing that preceded access to the Website, and the patterns of searching and browsing on the Website.
                    The Website does not respond to web browsers’ Do Not Track signals.
                    Thus, your selection of the “do not track” option provided by your browser may not have any effect on our collection of cookie information for analytic and internal purposes.</p>
            </div>
            <div className="space-y-5">
                <h1 className="font-bold text-lg md:text-2xl">What does MedicoTech do with the information it collects?</h1>
                <p className="text-sm md:text-lg">
                This information is collected to help MedicoTech LLC. further develop its services, to provide access to valuable information and services, and to improve the functionality of our website.
                 We track the number of visitors to our website and make changes to the portions most frequently visited to improve user functionality. 
                 We analyze the ways users are directed to our website to improve advertising as well as enhance quality and methods of advertising.

If Personal Identifiable Information is submitted, we use the information at the time of collection for business purposes. 
For example: Email addresses – if email addresses are provided, we may use them for marketing, promotional and information purposes, such as, invitations, newsletters, marketing, and announcements. We may also share email addresses with partners and affiliates for their own marketing purposes, but will never share information with unaffiliated third parties.</p>
            </div>
            <div className="space-y-5">
                <h1 className="font-bold text-lg md:text-2xl">Passive Information Collection/Use of Cookies</h1>
                <p className="text-sm md:text-lg">
                A cookie is a small text file that is stored on a user’s computer for record-keeping purposes. Cookies are used on this site. We do not link the information we store in cookies to any personally identifiable information you submit while on our site. We use session ID cookies to make it easier for you to navigate our site. A session ID cookie expires when you close your browser. A persistent cookie remains on your hard drive for an extended period of time. You can remove cookies by following directions in your Internet browser’s “help” file. To learn more about cookies, please visit this link: Microsoft Cookies.

As users navigate through a website, certain information can be passively collected (that is, gathered without the user actively providing the information), using various technologies and means, such as Internet Protocol addresses, cookies, Internet tags, and navigational data collection. MedicoTech LLC. uses Internet Protocol (IP) addresses on this site. An IP address is a number assigned to a computer by the Internet service provider so that it may access the Internet. It is generally considered to be non-personally identifiable information because, in most cases, an IP address is dynamic (changing each time you connect to the Internet), rather than static (unique to a particular user’s computer). MedicoTech LLC. uses an IP address to diagnose problems with its server, report aggregate information and determine the fastest route for your computer to use in connecting to our site in order to administer and improve the website functionality.

MedicoTech LLC. logs IP addresses, or the location of your computer on the Internet, for systems administration and troubleshooting purposes. Log data is used in the aggregate to analyze usage of the website and may be used to contact you for purposes of promoting MedicoTech LLC. products or services. Your data will not be sold to, shared with, or otherwise made available to any third parties. If you reject cookies, you may still use our site, but your ability to use some areas of our site, will be limited.
                    </p>
            </div>
            <div className="space-y-5">
                <h1 className="font-bold text-lg md:text-2xl">Security</h1>
                <p className="text-sm md:text-lg">
                MedicoTech LLC. servers are US based in a well-monitored and secure data center. Moreover, data protection mechanisms, security layers and data encryption measures have been implemented to prevent unauthorized access. All PHI, as well as demographic information, is password protected and encrypted within the relevant databases. It is important for users to protect their password and computer from unauthorized access. When users submit personal information to the secure areas of MedicoTech's website, MedicoTech LLC. encrypts it using Secure Sockets Layer (“SSL”), a software encryption technology. Encryption protects information against unauthorized access and modification once it is stored in the database.
                    </p>
            </div>

            <div className="space-y-5">
                <h1 className="font-bold text-lg md:text-2xl">Privacy Policy Updates</h1>
                <p className="text-sm md:text-lg">
                MedicoTech LLC. reserves the right to make periodic updates and revisions to this policy, at any time. If we decide to change our privacy policy, we will post those changes to this privacy statement and other places we deem appropriate so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it. You are welcome to email us with questions or concerns at info@medicotechllc.com

                    </p>
            </div>
          

        </div>
    )
}

export default PrivacyPolicy