import React from 'react'
import Connect from '../Components/Contact/Connect'
import Talk from '../Components/Talk'
const Appointment = () => {
  return (
    <div>
        <Connect/>
        
       
    </div>
  )
}

export default Appointment